(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/hooks/assets/javascripts/use-memoized-selector.js') >= 0) return;  svs.modules.push('/components/lb-utils/hooks/assets/javascripts/use-memoized-selector.js');
"use strict";

const {
  useRef,
  useState,
  useEffect
} = React;
const useMemoizedSelector = function (makeSelector) {
  for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    args[_key - 1] = arguments[_key];
  }
  const [selector, setSelector] = useState(() => makeSelector(...args));
  const previousArgs = useRef(args);
  useEffect(() => {
    const isSame = args.every((arg, index) => arg === previousArgs.current[index]);
    if (!isSame || args.length !== previousArgs.current.length) {
      previousArgs.current = args;
      setSelector(() => makeSelector(...args));
    }
  }, [makeSelector, args]);
  useEffect(() => {
    setSelector(() => makeSelector(...previousArgs.current));
  }, [makeSelector]);
  return selector;
};
setGlobal('svs.components.lbUtils.hooks.useMemoizedSelector', useMemoizedSelector);

 })(window);